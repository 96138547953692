import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Seo from '../components/Seo'
import Layout from '../components/Layout'
import Header from '../components/Header'

export default function Home({ data }) {
  const home = data.home.edges[0].node.frontmatter
  const projects = data.projects.edges

  return (
    <Layout>
      <Seo />
      <Header />
      <main role='main'>
        <section className='flex flex-col justify-center min-h-screen py-120'>
          <div className='container'>
            <h2
              className='text-24 md:text-32 lg:text-40'
              dangerouslySetInnerHTML={{
                __html: home.text,
              }}
            />
          </div>
        </section>
        <section className='pb-120'>
          <div className='container-lg'>
            <div className='gap-x-40 gap-y-80 grid grid-cols-1 grid-flow-row-dense md:grid-cols-2 lg:gap-x-80'>
              {projects.map((project, index) => {
                project = project.node.frontmatter
                let classes = index % 2 ? ' md:col-start-2' : ' md:col-start-1'
                classes = index === 1 ? `${classes} md:row-start-2` : classes

                return (
                  <div
                    key={project.slug}
                    className={`col-span-1 row-span-2 ${classes}`}
                  >
                    <a
                      href={`/projects/${project.slug}`}
                      className='bg-grey duration-300 flex items-end p-20 relative transform lg:p-40 hover:-translate-y-20'
                    >
                      <div className='absolute filter-overlay filter-screen inset-0'>
                        <Img
                          fluid={project.image.src.childImageSharp.fluid}
                          alt={project.image.alt}
                          className='h-full max-w-full object-cover w-full'
                        />
                      </div>
                      <div className='aspect-h-5 aspect-w-4 w-0' />
                      <div className='leading-tight relative text-white z-20'>
                        <h2 className='mb-15 md:text-20 lg:text-24'>
                          {project.title}
                        </h2>
                        <h3 className='text-24 md:text-32 lg:text-40'>
                          {project.heading}
                        </h3>
                      </div>
                    </a>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export const QUERY = graphql`
  {
    home: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/home.md/" } }
    ) {
      edges {
        node {
          frontmatter {
            text
          }
        }
      }
    }
    projects: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/projects/" } }
      sort: { fields: [frontmatter___id], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            heading
            image {
              src {
                childImageSharp {
                  fluid(maxWidth: 1600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
